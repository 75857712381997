import * as React from 'react'
import dayjs from 'dayjs'

export default class SPDShiftTimeEntry extends React.Component {

  editTimesheet = (e) => {
    e.preventDefault()

    if(!this.props.isTimesheetFormActive) {

      if(this.props.editShift) {
        this.props.editShift(this.props.date, this.props.shift)
      }


    }
  }

  render() {

    const shift = this.props.shift

    //console.log(shift)

    const statusClass = ['SPDSchedule', 'shift', 'pending']
    let status = null

    let startTime = null
    let finishTime = null
    let hours = null
    let diffMin = 0

    let shiftStartTime = shift.startTime.split(':')
    let shiftFinishTime = shift.finishTime.split(':')

    let dayStart = dayjs(this.props.date)
    let dayFinish = dayjs(this.props.date)

    dayStart = dayStart.hour(shiftStartTime[0])
    dayStart = dayStart.minute(shiftStartTime[1])

    dayFinish = dayFinish.hour(shiftFinishTime[0])
    dayFinish = dayFinish.minute(shiftFinishTime[1])

    startTime = dayStart.format('h:mma')
    finishTime = dayFinish.format('h:mma')

    // Difference Hour
    diffMin = dayFinish.diff(dayStart, 'minute', true)

    if (diffMin > 0) {
      hours = Math.floor(diffMin / 60).toString()

      if (diffMin % 60 > 0) {
        hours += '.' + ((Math.round(diffMin % 60) / 60) * 100).toString()
      }

      hours += 'h'
    }

    status = !this.props.isTimesheetFormActive ? <div className="result pending"><i className="fas fa-edit"></i> Edit</div> : null

    return (
      <div className={statusClass.join(' ')} onClick={this.editTimesheet}>
        <div className="site">{shift.name} (scheduled shift)</div>
        <div className="timesheet">
          <div className="start">
            <div className="time">{startTime}</div>
          </div>
          <div className="end">
            <div className="time">{finishTime}</div>
          </div>
          <div className="hours">
            {hours}
          </div>
          <div className="status">
            {status}
          </div>
        </div>
      </div>
    )
  }


}
