import * as React from 'react'
import API from './../API/API'
import Loader from '../_ui/loader/Loader';
import SPDTimesheetsPayPeriodHeading from './SPDTimesheetsPayPeriodHeading.js';
import SPDTimesheetsHeaderDay from './SPDTimesheetsHeaderDay.js'
import SPDTimesheetsTimeEntry from './SPDTimesheetsTimeEntry.js'
import SPDShiftTimeEntry from './SPDShiftTimeEntry'
import SPDTimesheetsSubmitDay from './SPDTimesheetsSubmitDay.js'
import SPDTimesheetForm from './TimesheetForm/SPDTimesheetForm.js'


import dayjs from 'dayjs'

export default class SPDTimesheets extends React.Component {

  constructor(props) {
    super(props)

    //get the earliest monday
    // let prevMonday = new Date();
    // prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);

    const getThisWeekMonday = () => {
      let date = new Date();
      let day = date.getDay();

      let thisMonday = new Date();
      if(date.getDay() !== 1) {
        thisMonday.setDate(date.getDate() - (day-1));
      }

      return thisMonday;
    }

    const getPreviousMonday = () => {
      let date = new Date();
      let day = date.getDay();

      let prevMonday = new Date();
      if(date.getDay() === 1){
        //prevMonday.setDate(date.getDate() - 7);
        prevMonday = dayjs(date).subtract(7,'day').toDate()
      } else{
        //prevMonday.setDate(date.getDate() - (day-1));
        prevMonday = dayjs(date).subtract((day-1),'day').toDate()
      }

      return prevMonday;
    }

    const getLast2WeeksMonday = () => {
      let date = getPreviousMonday();
      let day = date.getDay();

      let prevMonday = new Date();

      if(date.getDay() === 1){
        //prevMonday.setDate(date.getDate() - 7);
        prevMonday = dayjs(date).subtract(7,'day').toDate()
      } else{
        //prevMonday.setDate(date.getDate() - (day-1));
        prevMonday = dayjs(date).subtract((day-1),'day').toDate()
      }
      return prevMonday;
    }

    let thisWeekMonday = getThisWeekMonday()

    let endOfThisWeek = new Date(thisWeekMonday)
    endOfThisWeek.setDate(thisWeekMonday.getDate() + 6)


    let prevMonday = getPreviousMonday()

    let endOfWeek = new Date(prevMonday)
    endOfWeek.setDate(prevMonday.getDate() + 6)

    let endOfLastWeek = new Date(prevMonday)
    endOfLastWeek.setDate(prevMonday.getDate() - 1)


    let payperiodOptions = []

    //if today is monday
    let today = new Date();

    if(today.getDay() === 1) {

      //this week
      payperiodOptions.push({
        startDate: getThisWeekMonday(),
        endDate: endOfThisWeek
      })

      //last week
      payperiodOptions.push({
        startDate: prevMonday,
        endDate: endOfWeek
      })

    } else {

      //last week
      payperiodOptions.push({
        startDate: prevMonday,
        endDate: endOfWeek
      })

      //last 2 week
      payperiodOptions.push({
        startDate: getLast2WeeksMonday(),
        endDate: endOfLastWeek
      });

    }




    this.state = {
      loading: false,
      entries: [],
      payPeriod: {
        // startDate: prevMonday,
        // endDate: endOfWeek
        startDate: getThisWeekMonday(),
        endDate: endOfThisWeek
      },
      payPeriodOptions: payperiodOptions,
      isTimesheetFormActive: false,
      schedule: null,
      date: null,
      rules: {
        monday: {
          minMins: 510
        },
        tuesday: {
          minMins: 510
        },
        wednesday: {
          minMins: 510
        },
        thursday: {
          minMins: 510
        },
        friday: {
          minMins: 510
        },
        saturday: {

        },
        sunday: {

        },
        week: {
          minMins: 2550
        }
      }
    }

  }

  componentDidMount() {
    this.fetchTimeEntries()
  }

  updatePayPeriod = (payPeriod) => {

    this.setState({
      payPeriod: payPeriod
    }, () => {
      this.fetchTimeEntries()
    })

  }

  fetchTimeEntries = () => {

    let startDate = dayjs(this.state.payPeriod.startDate).format('YYYY-MM-DD')
    let endDate = dayjs(this.state.payPeriod.endDate).format('YYYY-MM-DD')

    this.setState({
      loading: true
    }, () => {

      fetch(
        //this.props.API + `staff/${this.props.employee.ID}/timesheets/?start=${startDate}&end=${endDate}`
        //'https://mobileapi.dev.specialisedplumbing.com/timeentries'
        `${API}timeentries?clientID=${this.props.clientID}&employeeID=${this.props.employeeID}&dateStart=${startDate}&dateEnd=${endDate}`,
        {
          headers: {
            'Authorization': `Bearer ${this.props.idToken}`
          }
        }
      )
      .then(res => res.json())
      .then(data => {
        this.setState({
          entries: this.sortSchedules(data),
          loading: false,
          lastSavedDate: null
        })
      })

    })



  }

  sortSchedules = (schedules) => {
    //console.log(schedules)
    schedules.forEach(schedule => {
      schedule.attendSort = schedule.type === 'timesheet' ? schedule.timesheet.attend : true
    })
    return schedules.sort((a, b) => { return a.attendSort !== true || dayjs(a.start).isAfter(dayjs(b.start)) ? true : false })
  }

  // Activate Timesheet Form
  addTime = (date) => {

    this.setState({
      date: date,
      schedule: null,
      shift: null,
      isTimesheetFormActive: true,
      lastSavedDate: null
    }, () => {
      window.scrollTo(0, 0)
    })

  }

  editTime = (schedule) => {

    let date = null

    switch (schedule.type) {
      case 'timesheet':
        date = dayjs(schedule.timesheet.startDate)
        break
      case 'schedule':
        date = dayjs(schedule.schedule.start)
        break
      default:
        break
    }

    let updateState = {}
    updateState.isTimesheetFormActive = true
    updateState.lastSavedDate = null

    if (date !== null) {
      updateState.schedule = schedule
      updateState.shift = null
      updateState.date = date
    }

    this.setState(updateState, () => {
      window.scrollTo(0, 0)
    })
  }

  editShift = (date, shift) => {

    let updateState = {}
    updateState.isTimesheetFormActive = true
    updateState.lastSavedDate = null

    if (date !== null) {
      updateState.schedule = null
      updateState.shift = shift
      updateState.date = date
    }

    this.setState(updateState, () => {
      window.scrollTo(0, 0)
    })

  }

  // Disable Timesheet Form -> To the List
  viewTimesheetsList = () => {

    let scrollDate = this.state.date

    this.setState({
      schedule: null,
      date: null,
      isTimesheetFormActive: false
    }, () => {

      if(scrollDate != null) {
        this.scrollToDate(scrollDate)

      }

    })

  }

  updatedTimesheet = (timesheet) => {
    //console.log(timesheet)
    // console.log(this.state.entries)

    //update the timesheet entry

    let newTimesheets = []
    let timesheetFound = false

    this.state.entries.forEach(time => {
      switch(time.type) {
        case 'timesheet':

          if(time.timesheet._id === timesheet.timesheet._id) {
            timesheetFound = true
            newTimesheets.push(timesheet)
          } else {
            newTimesheets.push(time)
          }

          break

        case 'schedule':

          if(time.schedule._id === timesheet.timesheet.scheduleID) {
            timesheetFound = true
            newTimesheets.push(timesheet)
          } else {
            newTimesheets.push(time)
          }

          break

        default:
          newTimesheets.push(time)
          break
      }
    })

    //console.log('timesheetFound: ' + timesheetFound)

    if(!timesheetFound) {
      newTimesheets.push(timesheet)
    }

    //console.log('newTimesheets')
    //console.log(newTimesheets)

    //this.fetchTimeEntries()
    this.setState({
      entries: newTimesheets,
      isTimesheetFormActive: false,
      date: null,
      lastSavedDate: timesheet.start
    })
  }

  scrollToDate = (date) => {


    if(!this.state.isTimesheetFormActive) {

      const yOffset = -55
      let header = typeof date === 'string' ? document.getElementById('dateHeader-' + date) : document.getElementById('dateHeader-' + date.format('YYYY-MM-DD'))

      if(header) {
        const y = header.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      }

    } else {

      if(this.state.schedule === null) {

        this.setState({
          date: date
        })

      }

    }

  }

  render() {

    //console.log(this.props.shifts)

    let today = dayjs()
    //let tomorrow = today.add(1,'day')

    const startDate = dayjs(this.state.payPeriod.startDate)
    const endDate = dayjs(this.state.payPeriod.endDate)

    let schedules = []
    let currentDate = startDate

    const calculateDayTotalHours = (currentDate) => {
      //calculate the number of hours spent for the day
      let totalScheduledMins = 0

      if (this.state.entries.length > 0) {
        this.state.entries.forEach(schedule => {

          let startTime = null
          let finishTime = null

          switch (schedule.type) {
            case 'schedule':
              //dont calculate hours on schedules

              break

            case 'timesheet':

              //console.log(schedule.timesheet.attend)

              if(schedule.timesheet.attend && schedule.timesheet.attend === true) {

                let timesheetStartTime = schedule.timesheet.startTime.split(':')
                let timesheetEndTime = schedule.timesheet.endTime.split(':')

                startTime = dayjs()
                startTime = startTime.hour(parseInt(timesheetStartTime[0]))
                startTime = startTime.minute(parseInt(timesheetStartTime[1]))

                finishTime = dayjs()
                finishTime = finishTime.hour(parseInt(timesheetEndTime[0]))
                finishTime = finishTime.minute(parseInt(timesheetEndTime[1]))

                //if (dayjs(schedule.timesheet.startDate).isSame(currentDate, 'day')) {
                if(schedule.timesheet.startDate === currentDate.format('YYYY-MM-DD')) {
                  totalScheduledMins += finishTime.diff(startTime, 'minute')
                }

              }


              break

            default:
              break
          }


        })

      }

      //return hours
      return totalScheduledMins

    }

    while (currentDate.isSameOrBefore(endDate)) {

      let dayScheduleCount = 0
      let totalDayMins = calculateDayTotalHours(currentDate)

      if(!this.state.date) {
        let hours = ''
        if (totalDayMins > 0) {
          hours = Math.floor(totalDayMins / 60)
          if (totalDayMins % 60 > 0) {
            hours += "." + ((Math.round(totalDayMins % 60) / 60) * 100).toString()
          }
          hours += 'h'
        }

        //display header for current day
        schedules.push(<SPDTimesheetsHeaderDay
          key={`date-${currentDate.format('DD-MM-YYYY')}`}
          date={currentDate.format('dddd D MMMM YYYY')}
          currentDate={currentDate}
          hours={hours}
        />)

      } else if(currentDate.isSame(this.state.date, 'day')) {
        //display header for active day (other schedules on day)
        schedules.push(<SPDTimesheetsHeaderDay
          key={`date-${currentDate.format('DD-MM-YYYY')}-others`}
          date={'Other time entries on ' + currentDate.format('dddd D MMMM YYYY')}
          hours=""
        />)

      }




      //retreve the schedules for the day
      this.state.entries.forEach((schedule, i) => {

        //console.log(schedule.type)

        let scheduleStart = null
        let scheduleID = null
        let scheduleBlockCount = 0 //only allow 'first' schedules to be added
        let shift = null

        switch (schedule.type) {
          case 'schedule':
            scheduleStart = dayjs(schedule.schedule.start)
            scheduleID = schedule.schedule._id
            scheduleBlockCount = schedule.schedule.blockCount
            break

          case 'timesheet':
            scheduleStart = dayjs(schedule.timesheet.startDate)
            scheduleID = schedule.timesheet._id

            //check if the timesheet has a shift
            if(schedule.timesheet.shiftID) {
              if(this.props.shifts) {
                this.props.shifts.forEach((userShift, i) => {
                  if(userShift._id === schedule.timesheet.shiftID) {
                    shift = userShift
                  }
                })
              }
            }

            break

          default:
            break
        }

        let addSchedule = false

        if(!this.state.date) {

          //add the schedule if before today and there's no active date
          if (scheduleStart !== null && scheduleStart.isSame(currentDate, 'day') && currentDate.isBefore(today) && scheduleBlockCount === 0) {
            addSchedule = true
          }

        } else {

          let activeScheduleID = null

          if(this.state.schedule) {
            switch(this.state.schedule.type) {
              case 'schedule':
                activeScheduleID = this.state.schedule.schedule._id
                break

              case 'timesheet':
                activeScheduleID = this.state.schedule.timesheet._id
                break

              default:
                break
            }

          }

          //add the schedules from the same day as the active schedule, but is NOT the active schedule
          if(scheduleStart.isSame(this.state.date, 'day') && scheduleStart.isSame(currentDate, 'day') && scheduleID !== activeScheduleID && scheduleBlockCount === 0) {
            addSchedule = true
          }
        }

        if(addSchedule) {
          dayScheduleCount++;

          schedules.push(<SPDTimesheetsTimeEntry
            key={`schedule-${schedule.type}-${i}`}
            schedule={schedule}
            shift={shift}
            value={schedule.id}
            editTime={this.editTime}
            isTimesheetFormActive={this.state.isTimesheetFormActive}
          />)

          //lastScheduleFinishTime = dayjs(schedule.end)
        }


      });



      //reteieve any shifts for the day
      if(this.props.shifts) {
        this.props.shifts.forEach((shift, i) => {

          if(shift.startDay === currentDate.format('dddd')) {

            //check if there is a timsheet with this shiftID
            let timesheetExists = false

            this.state.entries.forEach((schedule, i) => {
              if(schedule.type === 'timesheet' && typeof schedule.timesheet.shiftID != 'undefined' && schedule.timesheet.shiftID === shift._id) {
                timesheetExists = true
              }
            })


            if(!timesheetExists && currentDate.isBefore(today)) {

              schedules.push(<SPDShiftTimeEntry
                key={`shift-${shift._id}-${i}`}
                shift={shift}
                date={currentDate}
                editShift={this.editShift}
                isTimesheetFormActive={this.state.isTimesheetFormActive}
              />)

            }


          }

        })
      }


      let validDayTimes = false

      //const totalDayMins = totalScheduledMins + totalGapMins
      const minMins = typeof this.state.rules[currentDate.format('dddd').toLowerCase()].minMins !== 'undefined' ? this.state.rules[currentDate.format('dddd').toLowerCase()].minMins : 0

      //each day must have a total time of 8.5h / 510
      if (totalDayMins >= minMins && dayScheduleCount > 0) {
        validDayTimes = true
      }

      if(!this.state.date && !this.state.isTimesheetFormActive) {

        //if(currentDate.isSameOrBefore(today)) {

          schedules.push(<SPDTimesheetsSubmitDay
            key={`scheduleSubmit-${currentDate.format('DD-MM-YYYY')}`}
            date={currentDate}
            addTime={this.addTime}
            disabled={!validDayTimes}
            minMins={minMins}
            scheduledMinutes={totalDayMins}
          />)

        //}


      }

      currentDate = currentDate.add(1, 'day')

    }

    return (
      <div id="SPDTimesheets">
        {
          !(this.state.isTimesheetFormActive && (this.state.schedule != null || this.state.shift != null)) ? (
            <SPDTimesheetsPayPeriodHeading
              payPeriod={this.state.payPeriod}
              schedules={this.state.entries}
              teams={this.props.teams}
              date={this.state.date}
              rules={this.state.rules}
              loading={this.state.loading}
              isTimesheetFormActive={this.state.isTimesheetFormActive}
              scrollToDate={this.scrollToDate}
              payPeriodOptions={this.state.payPeriodOptions}
              updatePayPeriod={this.updatePayPeriod}
            />
          ) : (
            <div className="SPDTimesheetFormSpacer"></div>
          )
        }

        {
          this.state.isTimesheetFormActive ? (
            <SPDTimesheetForm
              clientID={this.props.clientID}
              companyID={this.props.companyID}
              employeeID={this.props.employeeID}
              contractorID={this.props.contractorID}
              schedule={this.state.schedule}
              shift={this.state.shift}
              date={this.state.date}
              viewTimesheetsList={this.viewTimesheetsList}
              idToken={this.props.idToken}
              updatedTimesheet={this.updatedTimesheet}
            />
          ) : null
        }

        {
          !this.state.loading ? (
            <>
              {
                this.state.lastSavedDate ? (
                  <div id="SPDTimesheetForm"><div className="response success">Time Entry saved on {dayjs(this.state.lastSavedDate).format('dddd D MMMM YYYY')}</div></div>
                ) : null
              }

              <div id="SPDTimeEntries">
                {schedules}
              </div>
            </>
          ) : (
            <Loader label="Loading Timesheets" />
          )
        }

      </div>
    )
  }

}
