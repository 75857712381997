import * as React from 'react';

import API from './components/jsapp/API/API'

import SPDHeader from './components/jsapp/SPDHeader.js';
import Navbar from './components/jsapp/_ui/navbar/Navbar.jsx';
import SPDTimesheets from './components/jsapp/SPDTimesheet/SPDTimesheets'
import SPDDailyRunsheet from './components/jsapp/SPDDailyRunsheet/SPDDailyRunsheet'
import SPDDailyRunsheetJob from './components/jsapp/SPDDailyRunsheet/SPDDailyRunsheetJob'
import SPDWeeklyRunsheet from './components/jsapp/SPDWeeklyRunsheet/SPDWeeklyRunsheet'
//import SPDJobHistory from './components/jsapp/SPDJobHistory/SPDJobHistory'
import Login from './components/jsapp/_ui/login/Login.jsx'

import './App.scss';

class App extends React.Component {

  state = {
    loggedIn: false,
    activePage: 'runsheet/daily',
    activeUser: null,
    idToken: null,
    accessToken: null,
    schedule: [],
    prevPage: null,
    activeSchedule: null,
    activeScheduleDate: null,
    checkingToken: false
  }

  componentDidMount() {

    let sessionStorage = window.sessionStorage;

    let sessionRefreshToken = null
    let tokenKey = null

    Object.keys(sessionStorage).forEach(key => {
      if (key.indexOf('rfSPD_') > -1) {
        tokenKey = key
        sessionRefreshToken = sessionStorage.getItem(key)
      }
    })

    //console.log(sessionRefreshToken)

    if (sessionRefreshToken != null) {

      this.setState({
        checkingToken: true
      }, () => {

        //update the user tokens from refrehToken
        fetch(
          API + 'refreshtoken',
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: sessionRefreshToken })
          }
        )
        .then(res => {

          if(res.ok) {

            res.json().then(data => {
              //console.log(data)

              this.setState({
                idToken: data.idToken,
                accessToken: data.accessToken,
              }, () => {

                //login with token
                fetch(
                  API + 'token',
                  {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token: this.state.accessToken })
                  }
                )
                .then(res => {
                  //console.log(res.ok)

                  if (res.ok) {
                    res.json().then(data => {
                      //console.log(data)
                      this.setActiveUser(data)
                    })
                  } else {

                    this.setState({
                      checkingToken: false
                    }, () => {
                      //clear the sessionRefreshToken
                      window.sessionStorage.removeItem(tokenKey)

                    })

                  }



                })

              })

            })

          } else {
            //clear the sessionRefreshToken
            window.sessionStorage.removeItem(tokenKey)
          }

        })
        .catch(err => {

          this.setState({
            checkingToken: false
          }, () => {

            //clear the sessionRefreshToken
            window.sessionStorage.removeItem(tokenKey)

          })

        })



      })

    }

  }

  schedulesUpdated = () => {
    this.forceUpdate()
  }

  updateActivePage = (page, schedule, prevPage) => {
    this.setState({
      activePage: page,
      schedule: schedule,
      prevPage: prevPage
    })
  }

  setActiveUser = (user, idToken, accessToken) => {
    idToken = idToken || this.state.idToken
    this.setState({
      loggedIn: true,
      activeUser: user,
      idToken: idToken,
      accessToken: accessToken,
      checkingToken: false
    })
  }

  logoutUser = () => {
    this.setState({
      loggedIn: false,
      activeUser: null,
      idToken: false,
      checkingToken: false
    }, () => {

      let sessionStorage = window.sessionStorage;
      let tokenKey = null

      Object.keys(sessionStorage).forEach(key => {
          if (key.indexOf('rfSPD_') > -1) {
              tokenKey = key
          }
      })

      if (tokenKey != null) {
          window.sessionStorage.removeItem(tokenKey)
      }

    })
  }

  render() {
    // console.log(this.state.activePage)
    if (this.state.loggedIn) {

      const user = this.state.activeUser
      let page = null

      //console.log(user)

      switch (this.state.activePage) {
        case 'history':
          page = <SPDDailyRunsheetJob
            clientID={user.clientID}
            companyID={user.companies[0]._id}
            employeeID={user.type === 'employee' ? user.ID : null}
            contractorID={user.type === 'contractor' ? user.ID : null}
            user={user}
            updateActivePage={this.updateActivePage}
            idToken={this.state.idToken}
            schedule={this.state.schedule}
            prevPage={this.state.prevPage}
          />
          /*
          page = <SPDJobHistory
            updateActivePage={this.updateActivePage}
            idToken={this.state.idToken}
            schedule={this.state.schedule}
            prevPage={this.state.prevPage}
          />
          */
          break

        case 'timesheets':
          page = <SPDTimesheets
            clientID={user.clientID}
            companyID={user.companies[0]._id}
            employeeID={user.type === 'employee' ? user.ID : null}
            contractorID={user.type === 'contractor' ? user.ID : null}
            shifts={user.type === 'employee' ? user.shifts || [] : null}
            teams={user.teams || []}
            idToken={this.state.idToken}
          />
          break

        case 'runsheet/weekly':

          page = <SPDWeeklyRunsheet
            updateActivePage={this.updateActivePage}
            activePage={this.state.activePage}

            runsheet={this.state.runsheet}
            activeUser={this.state.activeUser}
            idToken={this.state.idToken}
          />
          break

        case 'runsheet/daily':
        default:
          page = <SPDDailyRunsheet
            updateActivePage={this.updateActivePage}
            activePage={this.state.activePage}

            runsheet={this.state.runsheet}
            activeUser={this.state.activeUser}
            idToken={this.state.idToken}
          />
          break
      }

      return (
        <div className="App">
          <header className="SPDHeader">
            <SPDHeader employee={this.state.activeUser} logoutUser={this.logoutUser} />
          </header>
          <main>
            { !this.state.checkingToken ? page : null }
          </main>
          {
            this.state.activePage !== 'history' ? <Navbar updateActivePage={this.updateActivePage} activePage={this.state.activePage} /> : null
          }
        </div>
      );
    }
    else {
      return (
        <div className="App">
          {
            !this.state.checkingToken ? (
              <Login setActiveUser={this.setActiveUser} />
            ) : (
              <div id="login">

                <div>

                  <div className="logo">
                    <img src="image/logo.png" alt="Specialised Plumbing & Drainage" />
                  </div>

                  <div className="response">
                    <p>Checking Login. Please wait.</p>
                  </div>

                </div>

              </div>
            )
          }
        </div>
      )
    }
  }
}

export default App;
