import * as React from 'react'

const SPDTimesheetsHeaderDay = (props) => {

  const dateID = props.currentDate ? 'dateHeader-' + props.currentDate.format('YYYY-MM-DD') : null

  return (
      <div className="SPDSHeaderDay" id={dateID}>
        <div className="date">
          {props.date}
        </div>
        <div className="hours">
          {props.hours}
        </div>
      </div>
  )

}

export default SPDTimesheetsHeaderDay
