import * as React from 'react'
import dayjs from 'dayjs'

export default class SPDJobHistorySchedule extends React.PureComponent {

  render() {

    const schedule = this.props.schedule

    let blockScheduleTime = dayjs(schedule.Date)

    return (

      <article className="SPDJobHistorySchedule">

        <div className="icon"><i className="far fa-calendar fa-fw"></i></div>
        <div>
          <div className="dateBox">
            <h5>{blockScheduleTime.format('ddd')}</h5>
            <h2>{blockScheduleTime.format('DD')}</h2>
            <h5>{blockScheduleTime.format('MMM')}</h5>
          </div>
        </div>
        <div className="detail">

          {
            schedule.sectionName && schedule.sectionName !== '' ? (
              <div className="section">{schedule.sectionName}</div>
            ) : null
          }

          {
            schedule.costCentreName && schedule.costCentreName !== '' ? (
              <div className="costCentre">{schedule.costCentreName}</div>
            ) : null
          }

          {
            schedule.Notes && schedule.Notes !== '' ? (
              <div className="scheduleNotes" dangerouslySetInnerHTML={{ __html: schedule.Notes }}></div>
            ) : null
          }

          <div className="staff">{schedule.Staff.Name}</div>
        </div>

      </article>

    )
  }

}
