import * as React from 'react'
import dayjs from 'dayjs'

export default class SPDJobHistoryWorkOrder extends React.PureComponent {

  render() {

    const workorder = this.props.workorder

    let blockScheduleTime = dayjs(workorder.WorkOrderDate)

    return (

      <article className="SPDJobHistorySchedule">
        <div className="icon"><i className="far fa-clipboard fa-fw"></i></div>
        <div>
          <div className="dateBox">
            <h5>{blockScheduleTime.format('ddd')}</h5>
            <h2>{blockScheduleTime.format('DD')}</h2>
            <h5>{blockScheduleTime.format('MMM')}</h5>
          </div>
        </div>
        <div className="detail">

          {
            workorder.sectionName && workorder.sectionName !== '' ? (
              <div className="section">{workorder.sectionName}</div>
            ) : null
          }

          {
            workorder.costCentreName && workorder.costCentreName !== '' ? (
              <div className="costCentre">{workorder.costCentreName}</div>
            ) : null
          }


          {
            workorder.DescriptionNotes !== '' ? (
              <div className="descriptionNotes" dangerouslySetInnerHTML={{ __html: workorder.DescriptionNotes }}></div>
            ) : null
          }

          {
            workorder.MaterialNotes !== '' ? (
              <div className="materialNotes" dangerouslySetInnerHTML={{ __html: workorder.MaterialNotes }}></div>
            ) : null
          }

          <div className="staff">{workorder.Staff.Name}</div>

        </div>
      </article>

    )
  }

}
