import * as React from 'react'
import dayjs from 'dayjs'

export default class SPDWeeklyRunsheetSchedule extends React.PureComponent {

  viewHistory = (e) => {
    e.preventDefault()
    if (this.props.updateActivePage) {
      this.props.updateActivePage('history', this.props.schedule, 'runsheet/weekly')
    }
  }

  render() {
    const schedule = this.props.schedule
    let crew = []
    if (schedule.jobcard.simPROScheduleCrew) {
      schedule.jobcard.simPROScheduleCrew.forEach((member, i) => {
        crew.push(<div key={'crew-' + schedule.id + '-crew-' + i}>{member}</div>)
      })
    }

    return (
      <article className="schedule">

        {/* Address */}
        <div className="container address" onClick={this.viewHistory}>
          <div className="dateBox">
            <div className="day">{dayjs(schedule.start).format('ddd')}</div>
            <div className="date">{dayjs(schedule.start).format('DD')}</div>
          </div>
          <div className="addressBox">
            <h2>{schedule.job.site.address} {schedule.job.site.suburb} {schedule.job.siteName}</h2>
            {
              schedule.jobcard.simPROJobID && schedule.job.site.name ?
                (<h2>Job {schedule.jobcard.simPROJobID} {<span>&#8282;</span>} {schedule.job.site.name}</h2>) :
                schedule.jobcard.simPROJobID ?
                  (<h2>Job {schedule.jobcard.simPROJobID}</h2>) :
                  schedule.job.site.name ?
                    (<h2>{schedule.job.site.name}</h2>) :
                    <h2>`No Site`</h2>
            }
          </div>
          <button className="button small"><i className="fas fa-history"></i> History</button>
        </div>

        {/* Detail */}
        <div className="container detail">
          <div className="notesTitle">{schedule.jobcard.simPROScheduleCostCentre}</div>
          <div className="row">
            <i className="far fa-file fa-fw"></i>
            <div className="notes" dangerouslySetInnerHTML={{
              __html:
                schedule.jobcard.notes ? schedule.jobcard.notes : `(No Notes)`
            }}></div>
          </div>

          {
            crew.length > 0 ? (
              <div className="row">
                <i className="fas fa-user fa-fw"></i>
                <div className="crew">
                  {crew}
                </div>
              </div>
            ) : null
          }

        </div>

      </article>
    )

  }

}
