import * as React from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import API from '../API/API'
import Loader from '../_ui/loader/Loader'
import SPDDailyRunsheetSchedule from './SPDDailyRunsheetSchedule'

export default class SPDDailyRunsheet extends React.Component {

  state = {
    activeDate: null,
    today: dayjs().startOf('day'),
    tomorrow: dayjs().add(1, 'day').endOf('day'),
    runsheet: [],
    //activeDate: '',
    isLoading: false
  }

  setToday = () => {
    this.setState({
      activeDate: dayjs(this.state.today).format('YYYY-MM-DD')
    })
  }

  setTomorrow = () => {
    this.setState({
      activeDate: dayjs(this.state.tomorrow).format('YYYY-MM-DD')
    })
  }

  componentDidMount() {
    this._isMounted = true
    this.setState({
      isLoading: true
    }, () => {
      this.setToday()
      axios({
        //url: API + `schedules?${this.props.activeUser.type}ID=${this.props.activeUser._id}&dateStart=${this.state.today.format('YYYY-MM-DD')}&dateEnd=${this.state.tomorrow.format('YYYY-MM-DD')}&dateStartUnix=${this.state.today.unix()}&dateEndUnix=${this.state.tomorrow.unix()}`,
        url: API + `schedules?${this.props.activeUser.type}ID=${this.props.activeUser.ID}&dateStartUnix=${this.state.today.unix()}&dateEndUnix=${this.state.tomorrow.unix()}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.props.idToken}`
        }
      })
      .then((res) => {
        if(this._isMounted) {
          this.setState({
            runsheet: res.data,
            isLoading: false
          })
        }

      })
      .catch(err => {
        console.error(err)
        if(this._isMounted) {
          this.setState({
            isLoading: false
          })
        }

      })

    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    // Need this to use is between
    const isBetween = require('dayjs/plugin/isBetween')
    dayjs.extend(isBetween)
    // console.log(this.state.runsheet)
    let schedules = []
    if (this.state.runsheet) {
      this.state.runsheet.forEach((schedule, i) => {
        if (dayjs(schedule.start).format('YYYY-MM-DD') === this.state.activeDate) {
          schedules.push(<SPDDailyRunsheetSchedule
            key={'runsheet-' + schedule.id + '-' + i}
            schedule={schedule}
            updateActivePage={this.props.updateActivePage}
            // prevPage={`runsheet/daily`}
            />)
        }
      })
    }

    return (
      <div id="SPDDailyRunsheet">

        <div id="SPDDailyRunsheetDates" className="container day">
          <button onClick={this.setToday} className={this.state.activeDate === dayjs(this.state.today).format('YYYY-MM-DD') ? 'button active' : 'button'}>
            <div className="weekday">{this.state.today.format('dddd')}</div>
            <div className="date">{this.state.today.format('DD')}</div>
          </button>
          <button onClick={this.setTomorrow} className={this.state.activeDate === dayjs(this.state.tomorrow).format('YYYY-MM-DD') ? 'button active' : 'button'}>
            <div className="weekday">{this.state.tomorrow.format('dddd')}</div>
            <div className="date">{this.state.tomorrow.format('DD')}</div>
          </button>
        </div>
        {
          this.state.isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {
                schedules.length === 0 ?
                  (
                    <div className="emptyPage">
                      <div className="pageStatusIcon"><i className="far fa-calendar-times"></i></div>
                      <small>No Jobs Currently Scheduled</small>
                    </div>
                  ) : (
                    <div id="SPDDailyRunsheetSchedules">
                      {schedules}
                    </div>
                  )
              }
            </React.Fragment>
          )
        }

      </div>

    )
  }
}
