import * as React from 'react'
import dayjs from 'dayjs'

export default class SPDWeeklyRunsheetBlank extends React.PureComponent {

  render() {

    const date = this.props.date

    //style this component

    return (
      <article className="schedule">

        <div className="container address disable">
          <div className="dateBox">
            <div className="day">{dayjs(date).format('ddd')}</div>
            <div className="date">{dayjs(date).format('DD')}</div>
          </div>
          <div className="addressBox">
            <h2>No work currently scheduled</h2>
          </div>

        </div>
      </article>
    )
  }
}
