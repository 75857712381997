import * as React from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import API from '../API/API'
import Loader from '../_ui/loader/Loader';
import SPDWeeklyRunsheetSchedule from './SPDWeeklyRunsheetSchedule'
import SPDWeeklyRunsheetBlank from './SPDWeeklyRunsheetBlank'

export default class SPDRunsheet extends React.Component {

  state = {
    thisWeek: dayjs().startOf('week'),
    nextWeek: dayjs().add(1, 'week').endOf('week'),
    runsheet: [],
    activeDate: ['', ''],
    isLoading: false
  }

  setThisWeek = () => {
    this.setState({
      activeDate: [this.state.thisWeek.startOf('week').format('YYYY-MM-DD'), this.state.thisWeek.endOf('week').format('YYYY-MM-DD')]
    })
  }

  setNextWeek = () => {
    this.setState({
      activeDate: [this.state.nextWeek.startOf('week').format('YYYY-MM-DD'), this.state.nextWeek.endOf('week').format('YYYY-MM-DD')]
    })
  }

  componentDidMount() {
    this._isMounted = true
    // console.log(this.props.activeUser.type)
    this.setState({
      isLoading: true
    }, () => {
      this.setThisWeek()
      axios({
        //url: API + `schedules?${this.props.activeUser.type}ID=${this.props.activeUser._id}&dateStart=${this.state.thisWeek.format('YYYY-MM-DD')}&dateEnd=${this.state.nextWeek.format('YYYY-MM-DD')}`,
        url: API + `schedules?${this.props.activeUser.type}ID=${this.props.activeUser.ID}&dateStartUnix=${this.state.thisWeek.unix()}&dateEndUnix=${this.state.nextWeek.unix()}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.props.idToken}`
        }
      })
        .then((res) => {

          if(this._isMounted) {
            this.setState({
              runsheet: res.data,
              isLoading: false
            })
          }

        })
        .catch(err => {
          console.error(err)
          this.setState({
            isLoading: false
          })
        })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    // Need this to use is between
    const isBetween = require('dayjs/plugin/isBetween')
    dayjs.extend(isBetween)

    let schedules = []
    if (this.state.runsheet) {
      //Get current loop
      let currentLoopDate = dayjs(this.state.activeDate[0])

      //add one day for before loop
      let endLoopDate = dayjs(this.state.activeDate[1]).add(1, 'day')

      while (currentLoopDate.isBefore(endLoopDate)) {
        let dayScheduleCount = 0
        //look for the schedules for the day
        this.state.runsheet.forEach((schedule, i) => {
          let dateLoad = dayjs(schedule.start).format('YYYY-MM-DD')
          // console.log(dateLoad)
          if (dayjs(dateLoad).isSame(currentLoopDate)) {
            schedules.push(<SPDWeeklyRunsheetSchedule
              key={'runsheet-' + schedule.id + '-' + i}
              schedule={schedule}
              updateActivePage={this.props.updateActivePage}
            />)
            dayScheduleCount++
          }
        })
        //no schedules found - display blank
        if (dayScheduleCount === 0) {
          schedules.push(<SPDWeeklyRunsheetBlank key={'runsheet-blank-' +
            currentLoopDate.format('YYYY-MM-DD')}
            date={currentLoopDate} />)
        }
        //add a day to current date
        currentLoopDate = currentLoopDate.add(1, 'day')

      }
    }

    // User toggle for showing only active
    // this.state.runsheet.forEach((schedule, i) => {
    //   let dateLoad = dayjs(schedule.start).format('YYYY-MM-DD')
    //   if (dayjs(dateLoad).isBetween
    //     (this.state.activeDate[0],
    //       this.state.activeDate[1], null, '[]')) {

    //     schedules.push(<SPDWeeklyRunsheetSchedule
    //       key={'runsheet-' + schedule.id + '-' + i}
    //       schedule={schedule} />)
    //   }
    // })

    return (

      <div id="SPDWeeklyRunsheet">
        <div id="SPDWeeklyRunsheetDates" className="container day">
          <button onClick={this.setThisWeek}
          className={this.state.activeDate[0] === this.state.thisWeek.startOf('week').format('YYYY-MM-DD') ? 'button active' : 'button'}>

            <div className="start">
              <div className="month">
                {this.state.thisWeek.startOf('week').format('MMMM')}
              </div>
              <div className="date">
                {this.state.thisWeek.startOf('week').format('DD')}
              </div>
            </div>
            <div className="">&mdash;</div>
            <div className="end">
              <div className="month">
                {this.state.thisWeek.endOf('week').format('MMMM')}
              </div>
              <div className="date">
                {this.state.thisWeek.endOf('week').format('DD')}
              </div>
            </div>


          </button>
          <button onClick={this.setNextWeek} className={this.state.activeDate[0] === this.state.nextWeek.startOf('week').format('YYYY-MM-DD') ? 'button active' : 'button'}>

            <div className="start">
              <div className="month">
                {this.state.nextWeek.startOf('week').format('MMMM')}
              </div>
              <div className="date">
                {this.state.nextWeek.startOf('week').format('DD')}
              </div>
            </div>
            <div className="">&mdash;</div>
            <div className="end">
              <div className="month">
                {this.state.nextWeek.endOf('week').format('MMMM')}
              </div>
              <div className="date">
                {this.state.nextWeek.endOf('week').format('DD')}
              </div>
            </div>

          </button>
        </div>
        {
          this.state.isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {
                schedules.length < 1 ?
                  (
                    <div className="emptyPage">
                      <div className="pageStatusIcon"><i className="far fa-calendar-times"></i></div>
                      <small>No Jobs Currently Scheduled</small>
                    </div>
                  ) : (
                    <div id="SPDWeeklyRunsheetSchedules">
                      {schedules}
                    </div>
                  )
              }
            </React.Fragment>
          )
        }
      </div>

    )
  }
}
