import React from 'react'

const SPDSiteFileFolder = (props) => {

  const folder = props.folder

  return (
    <div className="SPDFileFolder">
      <i className="far fa-folder-open"></i> <span className="folderName">{folder.Name}</span>
    </div>
  )
}

export default SPDSiteFileFolder
