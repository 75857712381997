import React from 'react'
import axios from 'axios'
import API from '../API/API'
import Select from 'react-select';

import Loader from '../../jsapp/_ui/loader/Loader'

export default class SPDPurchaseOrder extends React.Component {

  state = {
    simPROUsername: this.props.user.simPROUsername || '',
    simPROPassword: this.props.user.simPROPassword || '',
    simPROVendorID: '',
    simPROVendorNotes: '',
    loading: false,
    requesting: false,
    vendors: [],
    response: null,
    responseClass: null,
    result: null,
    showForm: true
  }

  componentDidMount() {
    this._isMounted = true

    this.setState({
      loading: true
    }, () => {

      //fetch vendors from API
      axios({
        url: `${API}integration/simpro/vendors?clientID=${this.props.clientID}&companyID=${this.props.companyID}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.props.idToken}`
        }
      })
      .then((res) => {

        //console.log(res)
        this.setState({
          vendors: res.data,
          loading: false
        })

      })
      .catch(err => {
        console.error(err)

        this.setState({
          vendors: [],
          loading: false
        })
      })

    })



  }

  componentWillUnmount() {
    this._isMounted = false
  }

  changeVendor = (val) => {
    this.setState({
      simPROVendorID: val.value
    })
  }

  updateField = (e) => {
    switch(e.target.name) {
      case 'simPROUsername':
      case 'simPROPassword':
      case 'simPROVendorNotes':
        this.setState({
          [e.target.name]: e.target.value
        })
        break
    }
  }

  requestPurchaseOrder = (e) => {
    e.preventDefault()

    if(this.state.simPROVendorID != '' && this.state.simPROUsername != '' && this.state.simPROPassword != null) {

      this.setState({
        requesting: true
      }, () => {

        const body = {
          companyID: this.props.companyID,
          clientID: this.props.clientID,
          employeeID: this.props.employeeID,
          simPROUsername: this.state.simPROUsername,
          simPROPassword: this.state.simPROPassword,
          simPROVendorID: this.state.simPROVendorID,
          simPROVendorNotes: this.state.simPROVendorNotes,
          simPROJobCostCentreID: this.props.schedule.simPROCostCentreID
        }

        //console.log(body)

        axios({
          url: `${API}integration/simpro/purchaseorder`,
          method: 'POST',
          data: body,
          headers: {
            'Authorization': `Bearer ${this.props.idToken}`
          }
        })
        .then((res) => {

          //console.log(res.data)

          this.setState({
            response: 'PO Number: ' + res.data.VendorOrderID,
            responseClass: 'success',
            requesting: false,
            simPROVendorNotes: '',
            showForm: false
          })

        })
        .catch(err => {
          console.error(err)

          this.setState({
            response: 'Could not request a Purchase Order',
            responseClass: 'error',
            requesting: false,
            showForm: true
          })
        })

      })



    } else {

      this.setState({
        response: 'Please fill in all fields',
        responseClass: 'error'
      })
    }
  }

  requestNewPO = (e) => {
    e.preventDefault()

    this.setState({
      simPROVendorID: '',
      simPROVendorNotes: '',
      loading: false,
      requesting: false,
      vendors: [],
      response: null,
      responseClass: null,
      result: null,
      showForm: true
    })
  }

  render() {

    let vendorOptions = []
    let vendorDefault = {}

    this.state.vendors.forEach(vendor => {
      vendorOptions.push({value: vendor.ID, label: vendor.Name})

      if(vendor.ID === this.state.simPROVendorID) {
        vendorDefault = {value: vendor.ID, label: vendor.Name}
      }
    })

    return (
      <div id="SPDPurchaseOrder">
        {
          this.state.loading ? (
            <Loader label="Requesting Vendors" />
          ) : (

            <>
              {
                this.state.requesting ? (
                  <Loader label="Requesting Purchase Order" />
                ) : (

                  <form onSubmit={this.requestPurchaseOrder}>

                    {
                      this.state.showForm ? (

                        <div className="purchaseOrderNotes">

                          <div className="response warning">
                            This Purchase Order Number is valid for ONE order only.<br />
                            Only order materials for this job.<br />
                            <strong>{this.props.schedule.job.siteName}</strong>
                          </div>

                        </div>


                      ) : null
                    }


                    {
                      this.state.response ? (
                        <div className={'response ' + this.state.responseClass}>{this.state.response}</div>
                      ) : null
                    }

                    {
                      this.state.showForm ? (

                        <fieldset>

                          <div className="formRow">
                            <label className="no-margin">
                              Supplier
                            </label>
                            <Select options={vendorOptions} className="reactSelect" onChange={this.changeVendor} value={vendorDefault} />
                          </div>

                          <div className="formRow">
                            <label>
                              Notes
                              <textarea name="simPROVendorNotes" value={this.state.simPROVendorNotes} onChange={this.updateField} />
                            </label>
                          </div>


                          <div className="formRow">
                            <label>
                              simPRO Username
                              <input type="text" name="simPROUsername" value={this.state.simPROUsername} onChange={this.updateField} required="required" />
                            </label>
                          </div>

                          <div className="formRow">
                            <label>
                              simPRO Password
                              <input type="password" name="simPROPassword" value={this.state.simPROPassword} onChange={this.updateField} required="required" />
                            </label>
                          </div>

                          <div className="formRow">
                            <button type="submit" className="button expand action">Request Purchase Order Number for {this.props.schedule.job.name}</button>
                          </div>

                        </fieldset>

                      ) : (
                        <button type="button" className="button expand action" onClick={this.requestNewPO}>Request a new Purchase Order</button>
                      )
                    }



                  </form>

                )
              }
            </>
          )
        }
      </div>
    )

  }
}
