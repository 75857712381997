import React from 'react';

import SPDJobHistory from './../SPDJobHistory/SPDJobHistory'
import SPDJobFiles from './../SPDJobFiles/SPDJobFiles'
import SPDSiteFiles from './../SPDSiteFiles/SPDSiteFiles'
import SPDPurchaseOrder from './../SPDPurchaseOrder/SPDPurchaseOrder'

export default class SPDDailyRunsheetJob extends React.Component {

  state = {
    activeTab: 'history',
  }

  updateActivePage = (e) => {
    e.preventDefault()
    if (this.props.updateActivePage) {
      this.props.updateActivePage(this.props.prevPage)
    }
    // console.log(this.props.prevPage)
  }

  showHistory = (e) => {
    e.preventDefault()
    this.setState({
      activeTab: 'history'
    })

  }

  showJobAttachments = (e) => {
    e.preventDefault()
    this.setState({
      activeTab: 'jobattachments'
    })

  }

  showSiteAttachments = (e) => {
    e.preventDefault()
    this.setState({
      activeTab: 'siteattachments'
    })

  }

  showPurchaseOrder = (e) => {
    e.preventDefault()
    this.setState({
      activeTab: 'purchaseorder'
    })
  }

  render() {

    const schedule = this.props.schedule

    let activeTab = null

    switch(this.state.activeTab) {
      case 'jobattachments':
        activeTab = <SPDJobFiles schedule={this.props.schedule} idToken={this.props.idToken} />
        break

      case 'siteattachments':
        activeTab = <SPDSiteFiles schedule={this.props.schedule} idToken={this.props.idToken} />
        break

      // case 'audits':
      //   activeTab =
      //   break

      case 'purchaseorder':
        activeTab = <SPDPurchaseOrder schedule={this.props.schedule} idToken={this.props.idToken} companyID={this.props.companyID} clientID={this.props.clientID} employeeID={this.props.employeeID} user={this.props.user} />
        break

      case 'history':
      default:
        activeTab = <SPDJobHistory schedule={this.props.schedule} idToken={this.props.idToken} />
        break
    }


    let showPurchaseOrder = false

    if(this.props.user.teams) {
      this.props.user.teams.forEach(team => {
        if(team.name.indexOf('Team Leader') > -1) {
          showPurchaseOrder = true
        }
      })
    }

    //TODO: Setup check against team leader team

    return (
      <>
        <div id="SPDDailyRunsheetJob">
          <div className="job">
            <div className="address">
              <h2>{schedule.job.site.address} {schedule.job.site.suburb} {schedule.job.siteName}</h2>
              <h2>Job {schedule.jobcard.simPROJobID} &bull; {schedule.job.site.name}</h2>
            </div>
            <button
              className="button small"
              onClick={this.updateActivePage}
            ><i className="fas fa-fw fa-angle-left"></i> Back</button>
          </div>
          <div className="jobTabs">
            <button className={this.state.activeTab === 'history' ? 'tab active' : 'tab'} type="button" onClick={this.showHistory}>History</button>
            <button className={this.state.activeTab === 'jobattachments' ? 'tab active' : 'tab'} type="button" onClick={this.showJobAttachments}>Job Files</button>
            <button className={this.state.activeTab === 'siteattachments' ? 'tab active' : 'tab'} type="button" onClick={this.showSiteAttachments}>Site Files</button>
            { showPurchaseOrder ? <button className={this.state.activeTab === 'purchaseorder' ? 'tab active' : 'tab'} type="button" onClick={this.showPurchaseOrder}>Purchase Order</button> : null }
          </div>
          {activeTab}
          { /*
          <div className="schedules">
            <button className="button expand" onClick={this.updateActivePage}>Back to Schedules</button>
          </div>
          */ }
        </div>
      </>
    )

  }


}
