import * as React from 'react'
import dayjs from 'dayjs'
import axios from 'axios'
import API from './../../API/API'
import Loader from '../../_ui/loader/Loader'
import Switch from '../../_ui/switch/Switch'
import SPDTimesheetFormAttachment from './SPDTimesheetFormAttachment'
import SPDTimesheetFormFile from './SPDTimesheetFormFile'

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')

dayjs.extend(isSameOrBefore)

export default class SPDTimesheetForm extends React.Component {

  constructor(props) {
    super(props)

    let startDate = ''
    let endDate = ''
    let startTime = ''
    let endTime = ''
    let notes = ''
    let files = []
    let attend = true

    // console.log(this.props.schedule)
    //console.log(this.props)

    if (this.props.schedule) {

      let dayjsStart = null
      let dayjsEnd = null

      switch (this.props.schedule.type) {
        case 'schedule':

          dayjsStart = dayjs(this.props.schedule.schedule.start)
          dayjsEnd = dayjs(this.props.schedule.schedule.end)

          startDate = dayjsStart.format('YYYY-MM-DD')
          endDate = dayjsEnd.format('YYYY-MM-DD')

          startTime = dayjsStart.format('HH:mm')
          endTime = dayjsEnd.format('HH:mm')

          break


        case 'timesheet':

          let timesheetStartTime = this.props.schedule.timesheet.startTime.split(':')
          let timesheetEndTime = this.props.schedule.timesheet.endTime.split(':')

          dayjsStart = dayjs()
          dayjsStart = dayjsStart.hour(parseInt(timesheetStartTime[0]))
          dayjsStart = dayjsStart.minute(parseInt(timesheetStartTime[1]))

          dayjsEnd = dayjs()
          dayjsEnd = dayjsEnd.hour(parseInt(timesheetEndTime[0]))
          dayjsEnd = dayjsEnd.minute(parseInt(timesheetEndTime[1]))

          startDate = this.props.schedule.timesheet.startDate //dayjsStart.format('YYYY-MM-DD')
          endDate = this.props.schedule.timesheet.endDate //dayjsEnd.format('YYYY-MM-DD')

          startTime = dayjsStart.format('HH:mm')
          endTime = dayjsEnd.format('HH:mm')

          notes = this.props.schedule.timesheet.notes || ''
          attend = typeof this.props.schedule.timesheet.attend != 'undefined' ? this.props.schedule.timesheet.attend : true
          files = this.props.schedule.timesheet.files || []

          break

        default:
          break
      }

    } else if (this.props.shift) {

      let dayjsDate = dayjs(this.props.date)

      startDate = dayjsDate.format('YYYY-MM-DD')
      endDate = dayjsDate.format('YYYY-MM-DD')

      let startTimeParts = this.props.shift.startTime.split(':')
      startTimeParts.pop()

      startTime = startTimeParts.join(':')

      let finishTimeParts= this.props.shift.finishTime.split(':')
      finishTimeParts.pop()

      endTime = finishTimeParts.join(':')

    } else if (this.props.date) {

      let dayjsDate = dayjs(this.props.date)

      startDate = dayjsDate.format('YYYY-MM-DD')
      endDate = dayjsDate.format('YYYY-MM-DD')
    }

    this.state = {
      startDate: startDate, //this.props.schedule ? dayjs(this.props.schedule.start).format('YYYY-MM-DD') : '',
      endDate: endDate, //this.props.schedule ? dayjs(this.props.schedule.start).format('YYYY-MM-DD') : '',
      startTime: startTime,
      endTime: endTime,
      type: this.props.schedule && this.props.schedule.timesheet && this.props.schedule.timesheet.type ? this.props.schedule.timesheet.type : this.props.shift ? 'ordinary' : 'timesheet',
      notes: notes,
      attachments: [], //new attachments
      files: files, //existing files
      loading: false,
      response: null,
      responseClass: '',
      attend: attend,
      schedule: this.props.schedule
    }

  }

  refNewAttachment = React.createRef()

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.date !== this.props.date) {

      let dayjsDate = dayjs(this.props.date)

      let startDate = dayjsDate.format('YYYY-MM-DD')
      let endDate = dayjsDate.format('YYYY-MM-DD')

      this.setState({
        startDate: startDate,
        endDate: endDate
      })

    }

  }

  updateValue = (e) => {

    // console.log(e.target.value)
    // console.log(e.target.name)
    switch (e.target.name) {
      case 'startTime':
      case 'endTime':

        let updateTime = this.parseTime(e.target.value)
        // console.log(updateTime)
        if (updateTime.min % 15 === 0) {
          let validTimes = false

          //create the updated schedule date
          let updateDate = dayjs()
          updateDate = updateDate.hour(updateTime.hour)
          updateDate = updateDate.minute(updateTime.min)

          //parse the other date
          let otherDate = dayjs()
          if (e.target.name === 'startTime') {
            let otherTime = this.parseTime(this.state.endTime)
            otherDate = otherDate.hour(otherTime.hour)
            otherDate = otherDate.minute(otherTime.min)

          } else {
            let otherTime = this.parseTime(this.state.startTime)
            otherDate = otherDate.hour(otherTime.hour)
            otherDate = otherDate.minute(otherTime.min)
          }

          if (otherDate.isValid()) {
            //check if the start / end dates are valid
            if (e.target.name === 'startTime') {
              if (updateDate.isBefore(otherDate)) {
                validTimes = true
              }
            } else {
              if (updateDate.isAfter(otherDate)) {
                validTimes = true
              }
            }

          } else {
            validTimes = true
          }

          if (validTimes) {
            this.setState({
              [e.target.name]: e.target.value
            })
          }
        }

        break

      default:
        this.setState({
          [e.target.name]: e.target.value
        })
        break

    }

  }

  parseTime = (time) => {

    let hourMin = time.split(':')

    return {
      hour: parseInt(hourMin[0]),
      min: parseInt(hourMin[1])
    }

  }

  updateNoAttend = (active) => {
    this.setState({
      attend: active
    })
  }

  uploadAttachment = (e) => {
    this.refNewAttachment.current.click();
  }

  fileSelected = (e) => {
    let fileValue = this.refNewAttachment.current.files[0]
    if (fileValue) {
      this.addAttachment(fileValue)
    }

  }

  addAttachment = (attachment) => {
    this.setState({
      attachments: [...this.state.attachments, attachment]
    })

  }

  removeAttachment = (attachment) => {
    this.setState({
      attachments: this.state.attachments.filter(att => { return att !== attachment })
    })

  }

  removeFile = (file) => {
    let fileID = file._id

    if(this.props.schedule.type == 'timesheet') {

      this.setState({
        loading: true
      }, () => {

        axios
          .delete(`${API}timesheet/${this.props.schedule.timesheet._id}/attachment/${fileID}`)
          .then(res => {
            //console.log(res)

            //remove the file from the attachment
            this.setState({
              files: this.props.schedule.timesheet.files.filter(attachment => attachment._id !== fileID),
              loading: false
            }, () => {

              if(this.props.updatedTimesheet) {
                let updatedTimesheet = this.props.schedule
                updatedTimesheet.timesheet.files = updatedTimesheet.timesheet.files.filter(attachment => attachment._id !== fileID)

                this.props.updatedTimesheet(updatedTimesheet)
              }

            })

          })
          .catch(err => {
            console.log(err)

            this.setState({
              loading: false
            })
          })


      })


    }


  }

  cancelTimesheet = (e) => {
    e.preventDefault()
    // console.log('this works on form')
    if (this.props.viewTimesheetsList) {
      this.props.viewTimesheetsList()
    }
  }

  saveTimesheet = (e) => {
    e.preventDefault()

    //console.log(this.props.schedule)
    //console.log(this.state.attachments)

    let saveTimesheet = {};

    if (this.props.schedule && this.props.schedule.type === 'schedule') {
      saveTimesheet.scheduleID = this.props.schedule.schedule._id
    } else if(this.props.shift) {
      saveTimesheet.shiftID = this.props.shift._id
    }

    if (this.props.employeeID) {
      saveTimesheet.employeeID = this.props.employeeID
    }

    if (this.props.contractorID) {
      saveTimesheet.contractorID = this.props.contractorID
    }

    saveTimesheet.clientID = this.props.clientID
    saveTimesheet.companyID = this.props.companyID
    saveTimesheet.startDate = this.state.startDate !== '' ? this.state.startDate : dayjs().format('YYYY-MM-DD')
    saveTimesheet.startTime = this.state.startTime
    saveTimesheet.endDate = this.state.endDate !== '' ? this.state.endDate : dayjs().format('YYYY-MM-DD')
    saveTimesheet.endTime = this.state.endTime
    saveTimesheet.type = this.state.type
    saveTimesheet.notes = this.state.notes
    saveTimesheet.attend = this.state.attend

    //console.log(saveTimesheet)

    this.setState({
      loading: true,
      response: null,
      responseClass: ''
    }, () => {

      window.scrollTo(0,0)

      let apiURL = API + 'timesheet'
      let apiMethod = 'post'

      //console.log(this.state.schedule)
      //console.log(this.state.schedule.type)

      if (this.state.schedule && this.state.schedule.type === 'timesheet') {
        apiURL = `${API}timesheet/${this.state.schedule.timesheet._id}`
        apiMethod = 'put'
      }

      axios({
        url: apiURL,
        method: apiMethod,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.idToken}`
        },
        data: saveTimesheet
      })
      .then((res) => {

        //console.log(res.data)
        //console.log(this.state.attachments.length + ' attachments')

        const newTimesheet = res.data
        const timesheetID = newTimesheet._id


        const uploadAttachment = async (attachment) => {
          return new Promise((resolve, reject) => {

            let fd = new FormData()
            fd.append('attachment', attachment)

            axios({
              url: `${API}timesheet/${timesheetID.toString()}/attachment`,
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.idToken}`
              },
              data: fd
            })
            .then(res => {
              //console.log(res)
              resolve(null)
            })
            .catch(err => {
              console.log(err)

              reject(err)
            })

          })
        }

        //Upload attachments in async
        Promise.allSettled(
          this.state.attachments.map(async (attachment) => {
            try {
              await uploadAttachment(attachment)
            } catch(err) {
              console.log(err)
            }
          })
        ).then(() => {

          //retrieve the new time entry
          axios.get(`${API}timeentry/${timesheetID}`)
            .then(res => {

              // console.log('new time entry')
              // console.log(res.data)
              // console.log(res.data.timesheet.files)

              this.setState({
                loading: false,
                schedule: res.data,
                response: 'Time Entry Saved',
                responseClass: 'success',
                files: res.data.timesheet.files || [],
                attachments: []
              }, () => {

                if(this.props.updatedTimesheet) {
                  this.props.updatedTimesheet(res.data)
                }

              })



            })
            .catch(err => {
              console.log(err)
            })

        })



      })
      .catch((err) => {
        console.log(err)
        //console.log(err.response)
        //console.log(err.data)

        let error = err.response.data ? err.response.data.error : 'Could not save Time Entry'

        console.log(error)

        this.setState({
          loading: false,
          response: error,
          responseClass: 'error'
        })

      })

    })


  }

  render() {

    let today = dayjs()

    const schedule = this.props.schedule
    const shift = this.props.shift

    let displayDate = null
    let durationLabel = null
    let attachments = []
    let files = []

    if (schedule) {
      const dayjsDate = dayjs.unix(schedule.startUnix)
      let scheduleTitle = ''

      if(typeof schedule.jobcard != 'undefined') {
        if(typeof schedule.jobcard.simPROJobID != 'undefined') {
          scheduleTitle = 'Job ' + schedule.jobcard.simPROJobID;
        }
      }

      if(typeof schedule.job != 'undefined') {
        if(typeof schedule.job.site != 'undefined') {
          if(scheduleTitle.length > 0) {
            scheduleTitle += ' • '
          }
          scheduleTitle += schedule.job.site.name
        }
      }

      if(schedule.timesheet && schedule.timesheet.shiftID && schedule.timesheet.shiftName) {
        scheduleTitle = `${schedule.timesheet.shiftName} (scheduled shift)`
      }

      displayDate = (
        <div className="timesheetHeader">
          <div className="date">{dayjsDate.format('dddd DD MMMM YYYY')}</div>
          <div className="job">{scheduleTitle}</div>
        </div>
      )
    } else if (shift) {

      const dayjsDate = dayjs(this.props.date)

      displayDate = (
        <div className="timesheetHeader">
          <div className="date">{dayjsDate.format('dddd DD MMMM YYYY')}</div>
          <div className="job">{shift.name} (scheduled shift)</div>
        </div>
      )

    }

    //console.log(this.state)

    if (this.state.startTime !== '' && this.state.endTime !== '') {
      let startTime = this.parseTime(this.state.startTime)
      let endTime = this.parseTime(this.state.endTime)

      let dayjsStart = dayjs()
      dayjsStart = dayjsStart.hour(startTime.hour)
      dayjsStart = dayjsStart.minute(startTime.min)

      let dayjsEnd = dayjs()
      dayjsEnd = dayjsEnd.hour(endTime.hour)
      dayjsEnd = dayjsEnd.minute(endTime.min)
      let diffMins = dayjsEnd.diff(dayjsStart, 'm')

      durationLabel = (diffMins / 60) + 'h'
    }

    if(!this.state.attend) {
      durationLabel = '-'
    }


    this.state.attachments.forEach((att, i) => {
      attachments.push(<SPDTimesheetFormAttachment key={'attachment-' + i} filename={att} removeAttachment={this.removeAttachment} />)
    })

    this.state.files.forEach((file, i) => {
      attachments.push(<SPDTimesheetFormFile key={'file-' + i} file={file} removeFile={this.removeFile} />)
    })

    //console.log(schedule)
    //console.log(this.state.response)

    let notesPlaceholder = null

    if(!this.props.shift) {
      notesPlaceholder = "Please enter in details about your job. e.g. address & suburb. Please only use one time entry per job."
    }

    let showScheduleJobType = this.props.date.isSameOrBefore(today) ? true : false

    if(this.props.shift) {
      showScheduleJobType = false
    } else if(schedule && typeof schedule.timesheet !== 'undefined' && typeof this.props.schedule.timesheet.shiftID !== 'undefined') {
      showScheduleJobType = false
    }


    return (
      <>
        {
          this.state.loading ? (

            <div className="emptyPage">
              <Loader label="Saving Time Entry" />
            </div>

          ) : (

            <div id="SPDTimesheetForm">

              {displayDate}
              <form onSubmit={this.saveTimesheet}>

                <fieldset>

                  <div className="formRow timeRow">

                    <div className="start">
                      <label htmlFor="timeStart">
                        Start
                        <input
                          type="time"
                          step="900"
                          name="startTime"
                          id="timeStart"
                          value={this.state.startTime}
                          onChange={this.updateValue}
                          required="required"
                          disabled={!this.state.attend ? 'disabled' : null}
                          />
                      </label>
                    </div>

                    <div className="end">
                      <label htmlFor="timeEnd">
                        End
                        <input type="time"
                          step="900"
                          name="endTime"
                          id="timeEnd"
                          value={this.state.endTime}
                          onChange={this.updateValue}
                          required="required"
                          disabled={!this.state.attend ? 'disabled' : null}
                          />
                      </label>
                    </div>

                    <div className="duration">
                      <label className="no-margin">Duration</label>
                      <div className="durationLabel">
                        {durationLabel}
                      </div>
                    </div>

                  </div>

                  {
                    schedule && (this.props.schedule.type == 'schedule' || this.props.schedule.timesheet.scheduleID) ? (

                      <div className="formRow switchRow">
                        <Switch active={this.state.attend} onToggle={this.updateNoAttend} /> <label>Attended job?</label>
                      </div>

                    ) : null
                  }

                  <div className="formRow typeRow">
                    <label>
                      Type
                      <select name="type" value={this.state.type} onChange={this.updateValue} required="required" className="option">
                        { showScheduleJobType ? <option value="schedule">Scheduled Job</option> : null }
                        { this.props.date.isSameOrBefore(today) ? <option value="ordinary">Ordinary</option> : null }
                        <option value="annualleave">Annual Leave</option>
                        { this.props.date.isSameOrBefore(today) ? <option value="sickleave">Sick Leave</option> : null }
                        <option value="unpaidleave">Unpaid Leave</option>
                        { this.props.date.isSameOrBefore(today) ? <option value="tradeschool">Trade School</option> : null }
                      </select>
                    </label>
                  </div>

                  <div className="formRow typeRow">
                    <label>
                      Notes
                      <textarea name="notes"
                        value={this.state.notes}
                        onChange={this.updateValue}
                        className="notes"
                        placeholder={notesPlaceholder}
                      />
                    </label>
                  </div>

                  <div className="formRow attachmentsRow">
                    <label className="no-margin">Attachments</label>

                    <div className="files">
                      {files}
                    </div>

                    <div className="attachments">
                      {attachments}
                    </div>

                    <input
                      type="file"
                      ref={this.refNewAttachment}
                      style={{ display: 'none' }}
                      onChange={this.fileSelected} />
                    <button
                      type="button"
                      onClick={this.uploadAttachment}
                      className="button attachment expand">Add Attachment
                      </button>
                  </div>

                </fieldset>


                {
                  this.state.response ? (
                    <div className={'response ' + this.state.responseClass}>{this.state.response}</div>
                  ) : null
                }


                <div className="formRow actions">
                  <button type="button" onClick={this.cancelTimesheet} className="button">Cancel</button>
                  <button type="submit" className="button positive">Save</button>
                </div>

              </form>

            </div>

          )
        }
      </>
    )
  }

}
