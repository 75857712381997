import React from 'react'
const Switch = (props) => {

  let switchClass = ['Switch']
  if(props.active) {
    switchClass.push('active')
  } else {
    switchClass.push('inactive')
  }

  const toggleSwitch = (e) => {
    e.preventDefault()
    if(props.onToggle) {
      props.onToggle(!props.active)
    }
  }

  return (
    <button type="button" className={switchClass.join(' ')} onClick={toggleSwitch}>
      <div className="toggle"></div>
    </button>
  )

}

export default Switch
