import * as React from 'react'

export default class NavbarItem extends React.PureComponent {

  updatePage = (e) => {
    e.preventDefault()
    if(this.props.updatePage) {
      this.props.updatePage(this.props.item.page)
    }
  }

  render() {

    const item = this.props.item
    let itemClass = []
    if(this.props.active) {
      itemClass.push('active')
    }

    return (
      <a className={itemClass.join('')} href={'/' + item.page} onClick={this.updatePage}>
        {item.icon}
        <p>{item.label}</p>
      </a>
    )

  }

}
