import * as React from 'react'
const Loader = (props) => {

  const label = props.label || 'Loading Schedule'

    return (
        <div id="Loader">
            <div className="loading">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <small className="loadingText">{label}</small>
        </div>
    )

}

export default Loader
