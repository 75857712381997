import React from 'react'

const SPDTimesheetsPayPeriodHeadingDate = (props) => {

  const scrollToDate = (e) => {
    e.preventDefault()
    if(props.scrollToDate) {
      props.scrollToDate(props.currentDate)
    }
  }

  return (
    <div className={props.periodClass.join(' ')} onClick={scrollToDate}>
      <label className="day">{props.currentDate.format('dd').substr(0, 1)}</label>
      <div className="date">{props.currentDate.format('D')}</div>
      <small className="hours">{props.hours}</small>
    </div>
  )

}

export default SPDTimesheetsPayPeriodHeadingDate
