import * as React from 'react'
import dayjs from 'dayjs'
import SPDTimesheetsPayPeriodHeadingDate from './SPDTimesheetsPayPeriodHeadingDate'
import SPDTimesheeetsPayPeriodOption from './SPDTimesheeetsPayPeriodOption'

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')

dayjs.extend(isSameOrBefore)

export default class SPDTimesheetsPayPeriodHeading extends React.Component {

  state = {
    displayPayPeriods: false
  }

  scrollToDate = (currentDate) => {
    if(this.props.scrollToDate) {
      this.props.scrollToDate(currentDate)
    }

  }

  selectPayPeriod = (e) => {
    e.preventDefault()

    this.setState({
      displayPayPeriods: true
    })
  }

  deselectPayPeriod = (e) => {
    e.preventDefault()
    this.setState({
      displayPayPeriods: false
    })
  }

  togglePayPeriod = (e) => {
    e.preventDefault()
    this.setState({
      displayPayPeriods: !this.state.displayPayPeriods
    })
  }

  payperiodSelected = (period) => {
    if(this.props.updatePayPeriod) {
      this.setState({
        displayPayPeriods: false
      }, () => {
        this.props.updatePayPeriod(period)
      })

    }

  }

  render() {
    const payPeriod = this.props.payPeriod

    const startDate = dayjs(payPeriod.startDate)
    const endDate = dayjs(payPeriod.endDate)

    //calculate hours
    let totalHours = ''
    let totalMins = 0

    if (this.props.schedules) {

      this.props.schedules.forEach(schedule => {

        /*var scheduleStart = dayjs(schedule.start)
        var scheduleEnd = dayjs(schedule.end)

        //if(!schedule.missed) {
          totalMins += scheduleEnd.diff(scheduleStart, 'minute')
        //}*/

        let startTime = null
        let finishTime = null

        switch (schedule.type) {
          case 'schedule':
            //dont calculate hours on schedules
            //startTime = dayjs(schedule.schedule.start)
            //finishTime = dayjs(schedule.schedule.end)
            //totalMins += finishTime.diff(startTime, 'minute')
            break

          case 'timesheet':

            if(schedule.timesheet.attend && schedule.timesheet.attend === true) {

              let timesheetStartTime = schedule.timesheet.startTime.split(':')
              let timesheetEndTime = schedule.timesheet.endTime.split(':')

              startTime = dayjs()
              startTime = startTime.hour(parseInt(timesheetStartTime[0]))
              startTime = startTime.minute(parseInt(timesheetStartTime[1]))

              finishTime = dayjs()
              finishTime = finishTime.hour(parseInt(timesheetEndTime[0]))
              finishTime = finishTime.minute(parseInt(timesheetEndTime[1]))

              totalMins += finishTime.diff(startTime, 'minute')

            }

            break

          default:
            break

        }

      })

      totalHours = Math.floor(totalMins / 60)
      if (totalMins % 60 > 0) {
        //totalHours += "." + (((totalMins % 60) / 60) * 10)
        totalHours += "." + ((Math.round(totalMins % 60) / 60) * 100).toString()
      }
    }


    var payPeriodDays = []
    let currentDate = startDate

    const calculateDayTotalHours = (currentDate) => {
      let hours = ''
      if (this.props.schedules) {
        let dateTotalMins = 0
        this.props.schedules.forEach(schedule => {

          let startTime = null
          let finishTime = null

          switch (schedule.type) {
            case 'schedule':
              //dont calculate hours from schedule
              /*startTime = dayjs(schedule.schedule.start)
              finishTime = dayjs(schedule.schedule.end)

              if (startTime.isSame(currentDate, 'day')) {
                dateTotalMins += finishTime.diff(startTime, 'minute')
              }*/
              break

            case 'timesheet':

              if(schedule.timesheet.attend && schedule.timesheet.attend === true) {

                let timesheetStartTime = schedule.timesheet.startTime.split(':')
                let timesheetEndTime = schedule.timesheet.endTime.split(':')

                startTime = dayjs()
                startTime = startTime.hour(parseInt(timesheetStartTime[0]))
                startTime = startTime.minute(parseInt(timesheetStartTime[1]))

                finishTime = dayjs()
                finishTime = finishTime.hour(parseInt(timesheetEndTime[0]))
                finishTime = finishTime.minute(parseInt(timesheetEndTime[1]))

                //if (dayjs(schedule.timesheet.startDate).isSame(currentDate, 'day')) {
                if(schedule.timesheet.startDate === currentDate.format('YYYY-MM-DD')) {
                  dateTotalMins += finishTime.diff(startTime, 'minute')
                }

              }

              break

            default:
              break
          }

        })

        if (dateTotalMins > 0) {
          hours = Math.floor(dateTotalMins / 60)
          if (dateTotalMins % 60 > 0) {
            //hours += "." + (((dateTotalMins % 60) / 60))
            hours += "." + ((Math.round(dateTotalMins % 60) / 60) * 100).toString()
          }

          hours += 'h'
        }

      }

      return hours

    }

    while (currentDate.isSameOrBefore(endDate)) {

      let periodClass = ['payPeriodDate']

      if(this.props.date && currentDate.isSame(this.props.date, 'day')) {
        periodClass.push('active')
      }

      payPeriodDays.push(
        <SPDTimesheetsPayPeriodHeadingDate key={`payPeriodDay-${currentDate.format('dd')}`} currentDate={currentDate} hours={calculateDayTotalHours(currentDate)} periodClass={periodClass} scrollToDate={this.scrollToDate} />
      )

      currentDate = currentDate.add(1, 'day')
    }

    let teamName = this.props.teams && this.props.teams.length > 0 ? this.props.teams[0].name : '(no team)'

    //setup styling for hours
    let totalHoursClass = ['output', 'hours']
    let iconHours = null

    if(!this.props.loading) {

      if(totalMins < this.props.rules.week.minMins) {
        totalHoursClass.push('invalid')
        iconHours = <i className="fas fa-times"></i>
      } else {
        totalHoursClass.push('valid')
        iconHours = <i className="fas fa-check"></i>
      }

    } else {
      totalHours = null
    }

    return (
      <React.Fragment>

        {
          !this.state.displayPayPeriods ? <small className="payperiodChangeNote"><button onClick={this.togglePayPeriod}>Tap to change pay week period <i className="fas fa-angle-down"></i></button></small> : null
        }

        <button className="upper payPeriodInfo" onClick={this.togglePayPeriod}>
          <div>
            <label>From</label>
            <div className="output">{startDate.format('D MMM')}</div>
          </div>
          <div>
            <label>To</label>
            <div className="output">{endDate.format('D MMM')}</div>
          </div>
          <div>
            <label>Hours</label>
            <div className={totalHoursClass.join(' ')}>{totalHours}{iconHours}</div>
          </div>
          <div>
            <label>Team</label>
            <div className="output">{teamName}</div>
          </div>
        </button>

        {
          this.state.displayPayPeriods ? (
            <>
              <h5 className="payperiodSelect">Select Pay Period</h5>
              <div id="SPDTimesheeetsPayPeriodOptions">
                {
                  this.props.payPeriodOptions.map((option, i) => {
                    return (
                      <SPDTimesheeetsPayPeriodOption key={'payPeriodOption-' + i} option={option} selectPayPeriod={this.payperiodSelected} />
                    )
                  })
                }
                { /*<button onClick={this.deselectPayPeriod}>Close</button>*/ }
              </div>
            </>
          ) : (
            <>

              <div className="middle payPeriodDays week">
                {payPeriodDays}
              </div>

              {
                !this.props.isTimesheetFormActive ? (
                  <div className="payPeriodNotes">

                    <div className="response warning">
                      Please correct and confirm your timesheets every day for approval from your supervisor.<br />
                      Any incomplete timesheets will miss the payroll deadline (Monday 10am).<br />
                      Tap the dates above to access your timesheets from last week.<br />
                      If you are missing any jobs or leave, please speak to your supervisor.<br />
                      <strong>Note: 30mins will be deducted out of each day for lunch.</strong>
                    </div>

                  </div>
                ) : null
              }

            </>
          )
        }


      </React.Fragment>
    )

  }
}
