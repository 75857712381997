import * as React from 'react'
// import './Navbar.scss'

import NavbarItem from './NavbarItem'

export default class Navbar extends React.Component {

    state = {
        page: this.props.page || null
    }

    menu = {
        title: 'Mobile Navbar',
        items: [
            /*{
                page: 'runsheet/weekly',
                label: 'Runsheet - Weekly',
                icon: <i className="fas fa-calendar-day"></i>
            },*/
            {
                page: 'runsheet/daily',
                label: 'Runsheet',
                icon: <i className="fas fa-calendar-day"></i>
            },
            {
                page: 'timesheets',
                label: 'Timesheets',
                icon: <i className="fas fa-calendar-week"></i>
            },
            /*{
                page: 'timesheet/schedule',
                label: 'Timesheet - Schedule',
                icon: <i className="fas fa-calendar-week"></i>
            },
            {
                page: 'timesheet/blank',
                label: 'Timesheet - Blank',
                icon: <i className="fas fa-calendar-week"></i>
            },*/
            /*{
                page: 'expenses',
                label: 'Expenses',
                icon: <i className="fas fa-receipt"></i>
            }*/
        ]
    }

    updatePage = (page) => {

        this.setState({
            page: page
        }, () => {

            if (this.props.updateActivePage) {
                this.props.updateActivePage(this.state.page)
            }

        })

    }

    render() {

        let items = []

        this.menu.items.forEach((item, i) => {
            items.push(<NavbarItem key={'navbaritem-' + i}
                active={item.page === this.props.activePage ? true : false}
                item={item}
                updatePage={this.updatePage} />)
        })

        return (
            <div className="navbar">
                {items}
            </div>
        )
    }
}
