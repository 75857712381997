import * as React from 'react'
import Swal from 'sweetalert2'

const SPDTimesheetFormFile = (props) => {

  //console.log(props)

  const removeFile = (e) => {
    e.preventDefault()

    Swal.fire({
      title: 'Confirm delete',
      text: `Do you want to delete ${props.file.name}?`,
      showCancelButton: true,
      confirmButtonColor: '#34aa44',
      cancelButtonColor: '#f85359',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      focusConfirm: false,
      buttonsStyling: false,
      customClass: {
          container: 'kmanAlert',
          confirmButton: 'button positive',
          cancelButton: 'button negative'
      }
    }).then((result) => {

      if (result.isConfirmed) {

        if(props.removeFile) {
          props.removeFile(props.file)
        }

      }

    })

  }

  return (
    <div className="SPDTimesheetFormFile">
      <div className="filename">
        <div className="name">{props.file.name}</div>
        <button type="button" onClick={removeFile}><i className="fas fa-trash"></i></button>
      </div>
    </div>
  )

}

export default SPDTimesheetFormFile
