import * as React from 'react'
import axios from 'axios'
import API from '../API/API'

import SPDJobHistorySchedule from './SPDJobHistorySchedule'
import SPDJobHistoryWorkOrder from './SPDJobHistoryWorkOrder'
import Loader from '../../jsapp/_ui/loader/Loader'

export default class SPDJobHistory extends React.Component {

  state = {
    history: [],
    loader: false
    // prevPage: this.props.prevPage || null
  }

  updateActivePage = (e) => {
    e.preventDefault()
    if (this.props.updateActivePage) {
      this.props.updateActivePage(this.props.prevPage)
    }
    // console.log(this.props.prevPage)
  }


  componentDidMount() {
    this._isMounted = true
    // console.log(this.props.schedule)
    this.setState({
      loader: true
    }, () => {
      if (this.props.schedule) {
        const schedule = this.props.schedule
        //Sample URL for testing
        //https://mobileapi.dev.specialisedplumbing.com/integration/simpro/job/6123/history?clientID=5ffc10fa521a213728c40476&companyID=6013903829536b2a6c2f80b8
        axios({
          url: API + `integration/simpro/job/${schedule.jobcard.simPROJobID}/history?clientID=${schedule.clientID}&companyID=${schedule.companyID}`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.props.idToken}`
          }
        })
          .then((res) => {
            // console.log(res)
            if(this._isMounted) {
              this.setState({
                history: res.data,
                loader: false
              })
            }

          })
          .catch(err => {
            console.error(err)
          })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {

    const schedule = this.props.schedule
    // console.log(schedules)
    let history = []
    this.state.history.forEach((h, i) => {
      switch (h.ItemType) {
        case 'workorder':
          history.push(<SPDJobHistoryWorkOrder key={'workorder-' + h.ID + '-' + i} workorder={h} />)
          break

        case 'schedule':
          history.push(<SPDJobHistorySchedule key={'schedule-' + h.ID + '-' + i} schedule={h} />)
          break

        default:
          break
      }
    })
    return (
      <div id="SPDJobHistory">
        { /*
        <div className="job">
          <div className="address">
            <h2>{schedule.job.site.address} {schedule.job.site.suburb} {schedule.job.siteName}</h2>
            <h2>Job {schedule.jobcard.simPROJobID} &bull; {schedule.job.site.name}</h2>
          </div>
          <button
            className="button small"
            onClick={this.updateActivePage}
          ><i className="fas fa-fw fa-angle-left"></i> Back</button>
        </div>
        */ }
        {
          this.state.loader ?
            <Loader label="Loading Job History" /> :
            (
              <div className="history">
                {history}
              </div>
            )
        }
        { /*
        <div className="schedules">
          <button className="button expand" onClick={this.updateActivePage}>Back to Schedules</button>
        </div>
        */ }
      </div>
    )

  }
}
