import * as React from 'react'
export default class SPDTimesheetFormAttachment extends React.Component {

  state = {
    filename: ''
  }

  removeFile = (e) => {
    e.preventDefault()

    if(this.props.removeAttachment) {
      this.props.removeAttachment(this.props.filename)
    }
  }

  render() {

    //console.log(this.props.filename)

    let fileParts = this.props.filename.name.split('\\')

    return (
      <div className="SPDTimesheetFormAttachment">
        {
          this.props.filename ? (
            <div className="filename">
              <div className="name">{fileParts[fileParts.length - 1]}</div>
              <button type="button" onClick={this.removeFile}><i className="fas fa-times"></i></button>
            </div>
          ) : null
        }
      </div>
    )
  }
}
