import React, {useState} from 'react'
import API from '../API/API'

const SPDJobFileAttachment = (props) => {

  const file = props.file
  const schedule = props.schedule

  const [loading, setLoading] = useState(false);

  const viewFile = (e) => {
    e.preventDefault()

    if (schedule && !loading) {

      setLoading(true)

      //Sample URL for testing
      //https://mobileapi.dev.specialisedplumbing.com/integration/simpro/job/6123/history?clientID=5ffc10fa521a213728c40476&companyID=6013903829536b2a6c2f80b8
      fetch(
        API + `integration/simpro/job/${schedule.jobcard.simPROJobID}/attachment/${file.ID}?clientID=${schedule.clientID}&companyID=${schedule.companyID}`,
        {
          method: 'get',
          headers: {
            'Authorization': `Bearer ${props.idToken}`
          }
        }
      )
      .then(r => r.blob())
      .then((blob) => {
        showAttachment(blob)
        setLoading(false)
      })

    }

  }

  //https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
  const showAttachment = (blob) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let newBlob = new Blob([blob], {type: file.MimeType})

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = `${file.Filename}`
    link.click();
    setTimeout(function(){
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }


  return (
    <div className="SPDFileAttachment">
      {
        loading ? <div className="downloading">Downloading&hellip;</div> : <button onClick={viewFile}><i className="fas fa-external-link-alt"></i> {file.Filename}</button>
      }
    </div>
  )
}

export default SPDJobFileAttachment
