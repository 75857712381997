import axios from 'axios'
import * as React from 'react'
import API from '../../API/API'

export default class Login extends React.Component {

  state = {
    loading: false,
    username: '',
    password: '',
    isLogin: false,
    response: null,
    togglePassword: true
  }

  componentDidMount() {
    this._isMounted = true

    //update height for css variables
    const resizeHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }


    window.addEventListener('resize', () => {
      resizeHeight();
    });

    resizeHeight();
  }

  componentWillUnmount() {
      this._isMounted = false
  }

  handleChange = (event) => {
      this.setState({ [event.target.name]: event.target.value })
  }

  togglePassword = (e) => {
    e.preventDefault()
    this.setState({
      togglePassword: !this.state.togglePassword
    })
  }

  triggerLogin = (e) => {
    e.preventDefault()

    if (this.state.username !== '' && this.state.password !== '') {

      this.setState({
        loading: true,
        response: null
      }, () => {

        let loginSession = {
          username: this.state.username,
          password: this.state.password
        }

        axios({
          url: API + 'login',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: loginSession
        })
        .then((res) => {
          //console.log(res.data)

          let user = res.data
          user._id = res.data.ID

          this.setState({
            loading: true,
            refreshToken: user.refreshToken
          }, () => {

            //console.log(this.state.refreshToken)

            //save the refreshToken into sessionStorage
            window.sessionStorage.setItem('rfSPD_' + user._id, user.refreshToken)

            if (this.props.setActiveUser) {
              this.props.setActiveUser(user, res.data.idToken, res.data.accessToken)
            }

          });
        })
        .catch(err => {
          //console.log(err)

          //Cant use 401 response due to API gateway limitation
          const resp = 'Cannot log in. Please try again.'

          this.setState({
              loading: false,
              //response: err.response.data.error || 'Cannot log in. Please try again.'
              response: resp
          })

        })


      })

    }
  }



  render() {
    return (
      <div id="login">

        <form method="post" onSubmit={this.triggerLogin}>

          <div className="logo">
            <img src="image/logo.png" alt="Specialised Plumbing & Drainage" />
          </div>

          {
            this.state.loading ? (
              <p className="text-center">Logging in. Please wait.</p>
            ) : (

              <>

                {
                  this.state.response ? (
                    <div className="response">{this.state.response}</div>
                  ) : null
                }

                <label>
                  Username
                  <div className="field">
                    <input
                      type="text"
                      className="username"
                      name="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      required="required"
                      autoCorrect="off"
                      autoCapitalize="none"
                      />
                  </div>
                </label>


                <label>
                  Password
                  <div className="field loginPassword">
                    <input
                      type={this.state.togglePassword ? "password" : "text"}
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      required="required"
                      autoCorrect="off"
                      autoCapitalize="none"
                    />
                    <button
                      type="button"
                      className="togglePassword"
                      onClick={this.togglePassword}>
                        {
                          this.state.togglePassword ? (
                            <i className="far fa-eye"></i>
                          ) : (
                            <i className="fas fa-eye-slash"></i>
                          )
                        }
                    </button>
                  </div>
                </label>

                <button type="submit" className="button positive login expand">Log in</button>

              </>
            )
          }


        </form>

      </div>
    )
  }

}
