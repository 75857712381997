import * as React from 'react'
import Swal from 'sweetalert2'

export default class SPDHeader extends React.Component {

    triggerLogOut = (e) => {
      e.preventDefault()

      Swal.fire({
        title: 'Confirm log out?',
        showCancelButton: true,
        confirmButtonColor: '#34aa44',
        cancelButtonColor: '#f85359',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        focusConfirm: false,
        buttonsStyling: false,
        customClass: {
          container: 'kmanAlert',
          confirmButton: 'button positive',
          cancelButton: 'button negative'
        }
      })
      .then((result) => {

        if (result.isConfirmed) {

          if (this.props.logoutUser) {
            this.props.logoutUser()
          }

        }

      })

    }

    triggerMenu = (e) => {
      e.preventDefault()
    }

    render() {
      return (
        <React.Fragment>
          <button className="menu" onClick={this.triggerMenu}></button>
          <h1>{this.props.employee.firstName} {this.props.employee.lastName}</h1>
          <button type="button" className="logout" onClick={this.triggerLogOut}><i className="fas fa-sign-out-alt"></i> Log Out</button>
        </React.Fragment>
      )
    }
}
