import * as React from 'react'
import dayjs from 'dayjs'

export default class SPDTimesheetsTimeEntry extends React.Component {

  refStart = React.createRef()
  refEnd = React.createRef()

  handleChange = (e) => {

    var updateTime = this.parseTime(e.target.value)

    if (updateTime.min % 15 === 0) {

      //create the updated schedule date
      var updateDate = dayjs(this.props.schedule[e.target.name])

      updateDate = updateDate.hour(updateTime.hour)
      updateDate = updateDate.minute(updateTime.min)

      //get the other date
      var otherDate = e.target.name === 'start' ? dayjs(this.props.schedule['end']) : dayjs(this.props.schedule['start'])
      var validTimes = false

      //check if the start / end dates are valid
      if (e.target.name === 'start') {

        if (updateDate.isBefore(otherDate)) {
          validTimes = true
        }

      } else {

        if (updateDate.isAfter(otherDate)) {
          validTimes = true
        }

      }

      if (validTimes) {

        this.setState({
          [e.target.name]: e.target.value
        }, () => {

          this.props.schedule[e.target.name] = updateDate.format()

          if (this.props.onTimeUpdate) {
            this.props.onTimeUpdate(this.props.schedule, false)
          }

        })

      }


    }
  }

  sortSchedules = () => {

    //small delay check in case user moves to next field quickly
    setTimeout(() => {

      if (this.refStart.current !== document.activeElement && this.refEnd.current !== document.activeElement && this.props.onTimeUpdate) {
        this.props.onTimeUpdate(this.props.schedule, true)
      }

    }, 250)

  }


  parseTime = (time) => {

    var hourMin = time.split(':')

    return {
      hour: parseInt(hourMin[0]),
      min: parseInt(hourMin[1])
    }

  }

  getHour = (time) => {
    return this.parseTime(time).hour
  }

  getMin = (time) => {
    return this.parseTime(time).min
  }


  editTimesheet = (e) => {
    e.preventDefault()

    if(!this.props.isTimesheetFormActive) {

      let editable = true

      const schedule = this.props.schedule
      if (schedule.type === 'timesheet') {
        if (schedule.timesheet.approved === true || schedule.timesheet.rejected === true) {
          editable = false
        }
      }

      if (editable && this.props.editTime) {
        this.props.editTime(this.props.schedule)
      }

    }



  }

  render() {
    const schedule = this.props.schedule

    const statusClass = ['SPDSchedule']
    let status = null

    let startTime = null
    let finishTime = null
    let hours = null
    let diffMin = 0
    let scheduleTitle = ''
    let attend = true

    statusClass.push(schedule.type)

    if(typeof schedule.jobcard != 'undefined') {
      if(typeof schedule.jobcard.simPROJobID != 'undefined') {
        scheduleTitle = 'Job ' + schedule.jobcard.simPROJobID
      }
    }

    if(typeof schedule.job != 'undefined') {
      if(typeof schedule.job.site != 'undefined') {
        if(scheduleTitle.length > 0) {
          scheduleTitle += ' • '
        }
        scheduleTitle += schedule.job.site.name
      }
    }

    switch (schedule.type) {
      case 'schedule':

        statusClass.push('sch-' + schedule.schedule._id)
        statusClass.push('sch-block-' + schedule.schedule.blockCount)

        startTime = dayjs(schedule.schedule.start).format('h:mma')
        finishTime = dayjs(schedule.schedule.end).format('h:mma')

        // Difference Hour
        diffMin = dayjs(schedule.schedule.end).diff(dayjs(schedule.schedule.start), 'minute', true)


        status = !this.props.isTimesheetFormActive ? <div className="result pending"><i className="fas fa-edit"></i> Edit</div> : null

        statusClass.push('pending')

        break

      case 'timesheet':

        //console.log(schedule.timesheet)

        statusClass.push('ts-' + schedule.timesheet._id)

        attend = typeof this.props.schedule.timesheet.attend != 'undefined' ? this.props.schedule.timesheet.attend : true

        let timesheetStartTime = schedule.timesheet.startTime.split(':')
        let timesheetEndTime = schedule.timesheet.endTime.split(':')

        let dayTimesheetStart = dayjs()
        dayTimesheetStart = dayTimesheetStart.hour(parseInt(timesheetStartTime[0]))
        dayTimesheetStart = dayTimesheetStart.minute(parseInt(timesheetStartTime[1]))

        let updatedTimesheetEnd = dayjs()
        updatedTimesheetEnd = updatedTimesheetEnd.hour(parseInt(timesheetEndTime[0]))
        updatedTimesheetEnd = updatedTimesheetEnd.minute(parseInt(timesheetEndTime[1]))

        startTime = dayTimesheetStart.format('h:mma') //dayjs(schedule.timesheet.startTime).format('h:mma')
        finishTime = updatedTimesheetEnd.format('h:mma') //dayjs(schedule.timesheet.endTime).format('h:mma')

        diffMin = updatedTimesheetEnd.diff(dayTimesheetStart, 'minute', true)

        if (schedule.timesheet.approved === true) {
          status = <div className="result approved"><i className="fas fa-check"></i> Approved</div>
          statusClass.push('approved')
          //btn = 'Approved'
        } else if (schedule.timesheet.rejected === true) {
          status = <div className="result rejected"><i className="fas fa-times"></i> Rejected</div>
          statusClass.push('rejected')
        } else {
          status = <div className="result pending"><i className="fas fa-question"></i> Pending</div>
          statusClass.push('pending')
        }

        //console.log(schedule.timesheet.type)

        if(schedule.timesheet.shiftID) {

          scheduleTitle = `${schedule.timesheet.shiftName} (scheduled shift)`

        } else {

          switch(schedule.timesheet.type) {
            case 'unpaidleave':
              scheduleTitle = 'Unpaid Leave'
              break

            case 'annualleave':
              scheduleTitle = 'Annual Leave'
              break

            case 'sickleave':
              scheduleTitle = 'Sick Leave'
              break

            case 'unpaidleave':
              scheduleTitle = 'Unpaid Leave'
              break

            case 'tradeschool':
              scheduleTitle = 'Trade School'
              break

            default:
              break
          }

        }



        break

      default:
        break
    }



    if (diffMin > 0) {
      hours = Math.floor(diffMin / 60).toString()

      if (diffMin % 60 > 0) {
        hours += '.' + ((Math.round(diffMin % 60) / 60) * 100).toString()
      }

      hours += 'h'
    }

    if(!attend) {
      scheduleTitle += ' • Did not attend'
      hours = <i className="fas fa-times"></i>
      startTime = 'n/a'
      finishTime = 'n/a'
    }

    return (
      <div className={statusClass.join(' ')} onClick={this.editTimesheet}>
        <div className="site">{scheduleTitle}</div>
        <div className="timesheet">
          <div className="start">
            <div className="time">{startTime}</div>
          </div>
          <div className="end">
            <div className="time">{finishTime}</div>
          </div>
          <div className="hours">
            {hours}
          </div>
          <div className="status">
            {status}
          </div>
        </div>
      </div>
    )
  }
}
