import React from 'react'
import dayjs from 'dayjs'

const SPDTimesheeetsPayPeriodOption = (props) => {

  const selectPayPeriod = (e) => {
    e.preventDefault()
    if(props.selectPayPeriod) {
      props.selectPayPeriod(props.option)
    }
  }

  return (
    <div className="SPDTimesheeetsPayPeriodOption">

      <div className="date">{dayjs(props.option.startDate).format('dddd D MMMM')} &mdash; {dayjs(props.option.endDate).format('dddd D MMMM')}</div>
      <button className="select" onClick={selectPayPeriod}>Select</button>

    </div>
  )

}

export default SPDTimesheeetsPayPeriodOption
