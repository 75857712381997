import * as React from 'react'
//import dayjs from 'dayjs'
//var customParseFormat = require('dayjs/plugin/customParseFormat')
//dayjs.extend(customParseFormat)

export default class SPDTimesheetsSubmitDay extends React.Component {

  submitTimesheet = (e) => {
    e.preventDefault()
    if (!this.props.disabled) {
      if (this.props.submitTimesheets) {
        this.props.submitTimesheets(this.props.date)
      }
    } else {
      alert('Please fill in a full day. Min. 8.5h.')
    }
  }

  addTime = (e) => {
    e.preventDefault()

    if (this.props.addTime) {
      this.props.addTime(this.props.date)
    }
  }

  render() {

    let minTime = ''

    if (this.props.minMins && this.props.minMins > 0) {
      minTime += '(min. time required: ' + Math.floor(this.props.minMins / 60)
      if (this.props.minMins % 60 > 0) {
        minTime += "." + ((Math.round(this.props.minMins % 60) / 60) * 100).toString()
      }
      minTime += 'h)'
    }

    return (
      <div className="SPDSubmitDay">
        {
          this.props.disabled && this.props.minMins > 0 ? (
            <div className="incomplete text-center">Please complete your timesheets for {this.props.date.format('dddd D MMMM')}! {minTime}</div>
          ) : null
        }
        <div className="buttonRow">
          <button className="button expand action" onClick={this.addTime}>Add Time to {this.props.date.format('dddd D MMMM')}</button>
        </div>
      </div>
    )
  }

}
