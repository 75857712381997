import React from 'react'
import axios from 'axios'
import API from '../API/API'

import Loader from '../../jsapp/_ui/loader/Loader'
import SPDSiteFileFolder from './SPDSiteFileFolder'
import SPDSiteFileAttachment from './SPDSiteFileAttachment'

export default class SPDSiteFiles extends React.Component {

  state = {
    attachments: [],
    loading: false
  }

  componentDidMount() {
    this._isMounted = true

    this.setState({
      loading: true
    }, () => {
      if (this.props.schedule) {
        const schedule = this.props.schedule
        //Sample URL for testing
        //https://mobileapi.dev.specialisedplumbing.com/integration/simpro/job/6123/history?clientID=5ffc10fa521a213728c40476&companyID=6013903829536b2a6c2f80b8
        axios({
          url: API + `integration/simpro/site/${schedule.job.site.simPROSiteID}/attachments?clientID=${schedule.clientID}&companyID=${schedule.companyID}`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.props.idToken}`
          }
        })
        .then((res) => {
          // console.log(res)
          if(this._isMounted) {
            this.setState({
              attachments: res.data,
              loading: false
            })
          }

        })
        .catch(err => {
          console.error(err)
        })
      }
    })

  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {

    let files = []

    //console.log(this.state.attachments)

    this.state.attachments.forEach((folder, i) => {
      files.push(<SPDSiteFileFolder key={'folder-' + folder.ID + '-' + i} folder={folder} />)

      folder.files.forEach((file,j) => {
        files.push(<SPDSiteFileAttachment key={'folder-' + folder.ID + '-file-' + file.ID + '-' + j} file={file} schedule={this.props.schedule} idToken={this.props.idToken} />)
      })
    })

    return (
      <div id="SPDJobFiles">
        {
          this.state.loading ? (
            <Loader label="Loading Attachments" />
          ) : (
            <div className="attachments">{files}</div>
          )
        }
      </div>
    )

  }


}
